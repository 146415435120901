const SETTINGS = {
    chains: [
        "123XZfk9FWKyQoBCgBEiwDA4q1o9RdUSyB", 
        "1rmj3NjgDbCqRG7P2Be73xxwXzaW63ycm"
    ],
    peers: [
        "ws://hk.infnote.com:32767"
    ]
}

export default SETTINGS
